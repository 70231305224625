import React from "react"
import PropTypes from "prop-types"
import { WaveTopBottomLoading } from 'react-loadingg';
var parse = require('html-react-parser');

import I18n from "i18n/translations";

class BankID extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'bankIDSelect',
      errorMessage: null,
      // BankID creds
      fdn: '',
      otp: '',
      password: '',
      phone_number: '',
      birth_date: '',
      loading: true,
      test_status: null,
      // bankidElement: 'fdn',
    };
  }

  componentDidMount() {
    this.getSession();
  }

  isInputValid = (formData) => {
    return this.state.status == 'bankID' &&
            ((this.state.bankidElement == 'fdn' || this.state.bankidElement == undefined) && formData.fdn.length == 11 ||
              this.state.bankidElement == 'otp' && formData.otp.length >= 3 ||
              this.state.bankidElement == 'password' && formData.password.length >= 6) ||
            this.state.status == 'bankIDMobile' && (formData.phone_number.length == 8 && (formData.birth_date.length == 6 || formData.fdn.length == 11))
  }

  handleInputChange = (event) => {
    const target = event.target,
          value = target.value,
          name = target.name;

    let formData = {...this.state, [name]: value};

    this.setState({
      [name]: value,
      isInputValid: this.isInputValid(formData),
    });
  }

  setErrorMessage = (error) => {
    this.setState({ errorMessage: error.toString(), referenceCode: null, bankidElement: null, loading: false });
  }

  sendCode = (code) => {
    this.props.callback(code, 'OK', null, this.state.test_status);
  }

  getSession = () => {
    this.setState({ loading: true });

    let url = new URL(this.props.bankidSessionUrl, window.location.origin);
    Object.entries({ client_id: this.props.clientId }).forEach(([key, value]) => url.searchParams.append(key, value));

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }
      // console.log(respJSON);

      return respJSON.json();
    })
    .then(resp => {
      if (resp.status == 'error') {
        throw Error(resp.message);
      }

      this.setState({ sessionId: resp.session, loading: false, service_message: resp.message });
    })
    .catch(error => {
      // this.setErrorMessage(error);
      this.props.callback(null, 'error', 'TEMPORARY_ERROR');
    });
  }

  restartSession = () => {
    this.setState({ status: 'bankIDSelect', errorMessage: null, loading: true });
    this.getSession();
  }

  getData = (event) => {
    event.preventDefault();

    let formData = Object.fromEntries(new FormData(event.target));
    let comp = this, inputErrors = Object.values(formData).some(el => el.length < 3);

    if (!this.isInputValid(this.state)) { return }

    this.setState({ loading: true, bankIDError: null });

    fetch(this.props.bankdIDUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              session_id: this.state.sessionId,
              client_id: this.props.clientId,
              user_auth: formData,
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }

      return respJSON.json();
    })
    .then(resp => {
      if (resp.error_message) {
        throw Error(resp.error_message);
      }

      if (resp.bankid_element == 'reference') {
        this.setState({ referenceCode: resp.reference, bankidElement: resp.bankid_element, loading: false });
        this.checkBankIdStatus();
      } else if (resp.data_id) {
        // this.setState({ status: 'done', data: JSON.stringify(resp.data) });
        this.sendCode(resp.data_id);
      } else if (resp.bankid_element == 'app_reference') {
        this.setState({ referenceMsg: resp.hint, bankidElement: resp.bankid_element, loading: false });
        this.checkBankIdStatus();
      } else if (resp.status == 'error' && resp.action == 'restart') {
        this.restartSession();
      } else {
        let newState = { bankidElement: resp.bankid_element, bankIDError: resp.message, loading: false, otp: '' };
        if (this.state.bankidElement == 'password') { newState.bankidElement = 'password' };
        this.setState(newState);
      }
    })
    .catch(error => this.setErrorMessage(error));
  }

  checkBankIdStatus = () => {
    setTimeout(() => {
      fetch(this.props.bankdIDUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
                authenticity_token: this.props.authenticityToken,
                session_id: this.state.sessionId,
                client_id: this.props.clientId,
              }),
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.status == 'error') {
          throw Error(resp.message);
        }

        if (resp.bankid_element == 'reference') {
          this.checkBankIdStatus();
        } else {
          this.setState({ bankidElement: resp.bankid_element, loading: false, otp: '' });
        }
      })
      .catch(error => this.setErrorMessage(error));
    }, 1000)
  }

  getDataMobile = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let formData = Object.fromEntries(new FormData(event.target));
    let comp = this;

    fetch(this.props.bankdIDMobileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              session_id: this.state.sessionId,
              client_id: this.props.clientId,
              user_auth: formData,
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }
      // console.log(respJSON);

      return respJSON.json();
    })
    .then(resp => {
      if (resp.status == 'error') {
        throw Error(resp.message);
      }

      // console.log(resp);
      if (resp.reference) {
        comp.setState({ referenceCode: resp.reference, loading: false });
        this.checkBankIdMobileStatus();
      } else {
        // comp.setState({ status: 'done', data: JSON.stringify(resp.data) });
        this.sendCode(resp.data_id);
      }
    })
    .catch(error => this.setErrorMessage(error));
    // .catch(error => console.log(parse(error.toString())));
  }

  checkBankIdMobileStatus = () => {
    setTimeout(() => {
      fetch(this.props.bankdIDMobileUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
                authenticity_token: this.props.authenticityToken,
                session_id: this.state.sessionId,
                client_id: this.props.clientId,
              }),
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.status == 'error') {
          throw Error(resp.message);
        }

        if (resp.reference || !resp.data_id) {
          this.checkBankIdMobileStatus();
        } else {
          // this.setState({ status: 'done', data: JSON.stringify(resp.data) });
          this.sendCode(resp.data_id);
        }
      })
      .catch(error => this.setErrorMessage(error));
    }, 1000)
  }

  renderLoading = () => {
    return (
      <div className="row" style={{ marginBottom: 32, marginTop: 32 }}>
        <div className="col-2">
          <WaveTopBottomLoading color="#4579aa" />
        </div>
        <div className="col">{ I18n.t('bankid.loading') }</div>
      </div>
    )
  }

  renderReferenceCode = () => {
    return (
      <div className="row">
        <div className="col-2">
          <WaveTopBottomLoading color="#4579aa" />
        </div>
        <div className="col">
          {
            this.state.referenceCode &&
              <React.Fragment>
                <p>{ I18n.t('bankid.reference.code') } <strong>{ this.state.referenceCode }</strong></p>
                <p>{ I18n.t('bankid.reference.description') }</p>
              </React.Fragment>
          }
          {
            this.state.referenceMsg && <p>{ this.state.referenceMsg }</p>
          }
        </div>
      </div>
    )
  }

  getBankIDTest = (event, test_status) => {
    event.preventDefault();
    this.setState({ loading: true, status: 'bankIDTest', prevStatus: this.state.status, test_status: test_status });

    let comp = this;

    fetch(this.props.bankdIDTestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              session_id: this.state.sessionId,
              user_auth: {
                init: true,
              },
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }

      return respJSON.json();
    })
    .then(resp => {
      if (resp.status == 'error') {
        throw Error(resp.message);
      }

      if (resp.reference) {
        comp.setState({ referenceCode: resp.reference, loading: false });
        this.checkBankIdTestStatus();
      // } else {
      //   this.sendCode(resp.data_id);
      }
    })
    .catch(error => this.setErrorMessage(error));
  }

  checkBankIdTestStatus = () => {
    setTimeout(() => {
      fetch(this.props.bankdIDTestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
                authenticity_token: this.props.authenticityToken,
                session_id: this.state.sessionId,
                client_id: this.props.clientId,
              }),
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.status == 'error') {
          throw Error(resp.message);
        }

        if (resp.reference || !resp.data_id) {
          this.checkBankIdTestStatus();
        } else {
          this.sendCode(resp.data_id);
        }
      })
      .catch(error => this.setErrorMessage(error));
    }, 1000)
  }

  renderErrorMessage() {
    if (this.state.errorMessage) {
      return (
        <div>
          <p>{ I18n.t('bankid.error.title') }</p>
          <p>{ parse(this.state.errorMessage) }</p>
          <button className="btn btn-block btn-primary" onClick={ this.restartSession }>{ I18n.t('bankid.error.retry') }</button>
        </div>
      )
    }
  }

  renderServiceMessage() {
    if (this.state.service_message) {
      return (
        <div>
          <p>{ parse(this.state.service_message) }</p>
        </div>
      )
    }
  }

  renderForm() {
    switch(this.state.status) {
      case 'bankIDSelect': {
        return (
          <React.Fragment>
            { this.renderErrorMessage() }
            { this.renderServiceMessage() }
            <h3 className="text-center">{ I18n.t('bankid.method_select') }</h3>
            {
              this.props.test &&
                <React.Fragment>
                  <button className="btn btn-block btn-primary font-weight-bold" onClick={ e => this.getBankIDTest(e, 'done') } >
                    { 'Test data (bankID på mobil)' }
                  </button>
                  <button className="btn btn-block btn-primary font-weight-bold" onClick={ e => this.getBankIDTest(e, 'partially_done') } >
                    { 'Test partial data (bankID på mobil)' }
                  </button>
                  <button className="btn btn-block btn-primary font-weight-bold" onClick={ e => this.getBankIDTest(e, 'error') } >
                    { 'Test error data (bankID på mobil)' }
                  </button>
                </React.Fragment> ||
                <React.Fragment>
                  <button id="bank_id" className="btn btn-block btn-primary font-weight-bold" onClick={ () => this.setState({ status: 'bankID', prevStatus: this.state.status }) } >
                    { I18n.t('bankid.bankid_title') }
                  </button>
                  <button id="bank_id_mobile" className="btn btn-block btn-primary font-weight-bold" onClick={ () => this.setState({ status: 'bankIDMobile', prevStatus: this.state.status }) } >
                    { I18n.t('bankid.bankid_mobile_title') }
                  </button>
                </React.Fragment>
            }
          </React.Fragment>
        )

        break;
      }
      case 'bankID': {
        return (
          <React.Fragment>
            {
              this.state.bankidElement == 'reference' && this.renderReferenceCode() ||
              this.state.bankidElement == 'app_reference' && this.renderReferenceCode() ||
              this.renderErrorMessage()
                 ||
              <React.Fragment>
                {
                  this.state.bankIDError && <div><p>{ parse(this.state.bankIDError) }</p></div>
                }
                {
                  (this.state.bankidElement == 'fdn' || !this.state.bankidElement) &&
                    <form className="simple_form fdn" acceptCharset="UTF-8" method="POST" onSubmit={ this.getData }>
                      <div className="form-group tel required fdn">
                        <label className="form-control-label tel required" htmlFor="fdn">{ I18n.t('bankid.inputs.fdn') } <abbr title="required">*</abbr></label>
                        <input className="form-control string tel required" type="tel" maxLength="11" autoComplete="off" autoCorrect="off" spellCheck="off" name="fdn" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.fdn } />
                      </div>
                      <input type="submit" name="commit" disabled={ !this.isInputValid(this.state) } value={ I18n.t('common.next') } className="btn btn-block btn-primary" data-disable-with={ I18n.t('bankid.inputs.fdn_loading') } />
                    </form> ||
                    this.state.bankidElement == 'otp' &&
                      <form className="simple_form otp" acceptCharset="UTF-8" method="POST" onSubmit={ this.getData }>
                        <div className="form-group tel required otp">
                          <label className="form-control-label tel required" htmlFor="otp">{ I18n.t('bankid.inputs.otp') } <abbr title="required">*</abbr></label>
                          <input className="form-control string required" type="password" autoComplete="off" autoCorrect="off" spellCheck="off" name="otp" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.otp } />
                        </div>
                        <input type="submit" name="commit" disabled={ !this.isInputValid(this.state) } value={ I18n.t('common.next') } className="btn btn-block btn-primary" data-disable-with={ I18n.t('bankid.inputs.otp_loading') } />
                      </form> ||
                    this.state.bankidElement == 'password' &&
                      <form noValidate="novalidate" className="simple_form password" acceptCharset="UTF-8" method="POST" onSubmit={ this.getData }>
                        <div className="form-group tel required password">
                          <label className="form-control-label tel required" htmlFor="password">{ I18n.t('bankid.inputs.password') } <abbr title="required">*</abbr></label>
                          <input className="form-control string tel required" type="password" autoComplete="off" autoCorrect="off" spellCheck="off" name="password" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.password } />
                        </div>
                        <input type="submit" name="commit" disabled={ !this.isInputValid(this.state) } value={ I18n.t('common.next') } className="btn btn-block btn-primary" data-disable-with={ I18n.t('bankid.inputs.password_loading') } />
                      </form>
                }

              </React.Fragment>
            }
          </React.Fragment>
        )

        break;
      }
      case 'bankIDMobile': {
        return (
          <React.Fragment>
            {
              this.state.referenceCode && this.renderReferenceCode() ||
                this.renderErrorMessage() ||
                <form noValidate="novalidate" className="simple_form data" acceptCharset="UTF-8" method="POST" onSubmit={ this.getDataMobile }>
                  <div className="form-group tel required fdn">
                    <label className="form-control-label tel required" htmlFor="fdn">{ I18n.t('bankid.inputs.fdn') } <abbr title="required">*</abbr></label>
                    <input className="form-control string tel required" type="tel" maxLength="11" autoComplete="off" autoCorrect="off" spellCheck="off" name="fdn" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.fdn } />
                  </div>
                  <div className="form-group tel required data_phone_number">
                    <label className="form-control-label tel required" htmlFor="data_phone_number">{ I18n.t('bankid.inputs.phone_number') } <abbr title="required">*</abbr></label>
                    <input className="form-control string tel required" type="tel" pattern="[0-9]{8}" maxLength={8} name="phone_number" id="data_phone_number" onChange={ this.handleInputChange } value={ this.state.phone_number } />
                  </div>

                  <input type="submit" name="commit" disabled={ !this.isInputValid(this.state) } value={ I18n.t('common.next') } className="btn btn-block btn-primary" data-disable-with={ I18n.t('bankid.inputs.phone_number_loading') } />
                </form>
            }
          </React.Fragment>
        );
        break;
      }
      case 'bankIDTest': {
        return (
          <div className="row">
            <div className="col-2">
              <WaveTopBottomLoading color="#4579aa" />
            </div>
            <div className="col">
              <p>{ I18n.t('bankid.reference.code') } <strong>{ this.state.referenceCode }</strong></p>
              <p>{ I18n.t('bankid.reference.description') }</p>
            </div>
          </div>
        )
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        { this.state.loading && this.renderLoading() || this.renderForm() }
      </React.Fragment>
    );
  }
}

BankID.propTypes = {
  bankdIDUrl: PropTypes.string,
  bankdIDMobileUrl: PropTypes.string,
  bankdIDTestUrl: PropTypes.string,
  bankidSessionUrl: PropTypes.string,
  authenticityToken: PropTypes.string,
  clientId: PropTypes.string,
  callback: PropTypes.func,
  test: PropTypes.bool,
};
export default BankID
