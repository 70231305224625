import React from "react"
import PropTypes from "prop-types"
import { WaveTopBottomLoading } from 'react-loadingg';

import TransactionAggregator from './TransactionAggregator'
var parse = require('html-react-parser');

import I18n from "i18n/translations";

const errorMessages = {
  BAD_REQUEST: 'Bad request or wrong request parameters. Please, check the link',
  USER_CANCELLED: 'User canselled interactions',
  INTERNAL_ERROR: 'Something went wrong. Service is currently unavailable. Please, try again later.',
  TEMPORARY_ERROR: 'Service is currently partially unavailable due to a provider issue. Please, try again in a minute.',
}

class TheiaCollector extends React.Component {
  constructor(props) {
    super(props);
    // Set locale
    I18n.locale = props.locale;

    this.navigationStack = [];

    this.config = {
      ...{
          introDescription: I18n.t('link.intro.description'),
          showPolicyPrompt: true,
        },
      ...(props.customerConfig || {})[I18n.locale]
    };

    let status = 'intro';
    if (props.consentGiven) { status = 'aispAuth' }

    this.state = {
      status: status,
      consentGiven: props.consentGiven || false,
      dataId: props.dataId,
    };
  }

  pushCurrentStatus = () => {
    this.navigationStack.push(this.state.status);
  }

  start = (event) => {
    this.pushCurrentStatus();
    this.setState({ status: 'aispAuth' });
  }

  cancel = () => {
    this.callback(null, 'error', 'USER_CANCELLED');
  }

  policies = () => {
    this.pushCurrentStatus()
    this.setState({ status: 'policies' });
  }

  termsOfUse = () => {
    this.pushCurrentStatus();
    this.setState({ status: 'termsOfUse' });
  }

  privacyPolicy = () => {
    this.pushCurrentStatus();
    this.setState({ status: 'privacyPolicy' });
  }

  goBack = () => {
    this.setState({ status: this.navigationStack.pop() });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    let name = target.name;

    if (target.name.match(/phone/)) {
      name = 'phoneNumber';
    } else if (target.name.match(/birth/)) {
      name = 'birthDate';
    }

    this.setState({
      [name]: value
    });
  }

  changeConsent = (event) => {
    this.setState({ consentGiven: event.target.checked });
  }

  redirectBack = (code = null, errorType = null) => {
    let params = {};
    const errorMessage = errorMessages[errorType],
          url = new URL(this.props.callbackUrl, document.baseURI);

    if (errorType) {
      params.status = 'error';

      if (this.props.iframe) {
        params.error = {
          status: errorType,
          message: errorMessage,
        };
      } else {
        params.error = errorType;
        params.message = errorMessage;
      }
    } else {
      params.code = code;
    }

    if (this.props.iframe) {
      window.parent.postMessage(params, window.parent.location.origin);
    } else {
      Object.entries(params).forEach(([key, val]) => url.searchParams.append(key, val));

      window.location = `${url}`;
    }
  }

  callback = (code, status, errorType = null, test_status) => {
    let errorMessage = errorMessages[errorType];
    const data = { code: code, status: status, error: errorType, authenticity_token: this.props.authenticityToken,
      client_id: this.props.clientId, scope: this.props.scope, test: this.props.test, test_status: test_status };
    const url = new URL(this.props.callbackUrl, document.baseURI);
    // console.log(this.state, this);

    if (errorType) {
      this.setState({ status: 'redirect' });

      this.redirectBack(null, errorType);
    } else {
      this.setState({ status: 'collect_data' });

      fetch(this.props.dataCollectionURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(resp => resp.json())
      .then(jsonResp => {
        const dataId = jsonResp.data_id;

        this.setState({ status: 'aispAuth', dataId: dataId });
      })
      .catch(error => alert('Smth went wrong'));
    }
  }

  // Error format: { status: 'USER_CANCELLED', message: '' }
  aispCallback = (dataId, error = undefined) => {
    this.setState({ status: 'redirect' });
    const url = this.props.callbackUrl,
          message = { code: dataId, error: error?.message };

    if (this.props.iframe) {
      window.parent.postMessage(message, window.parent.location.origin);
    } else {
      const params = new URLSearchParams(Object.fromEntries(Object.entries(message).filter(([key, val]) => val))).toString();
      window.location = `${url}?${params}`;
    }
  }

  renderBackButton() {
    if (this.state.prevStatus) {
      return (
        <button className="btn btn-link float-left" onClick={ this.goBack }>
          <i className="fas fa-angle-left" />
        </button>
      )
    }
  }

  renderHeader(content) {
    return (
      <h3 className="text-center">
        { this.renderBackButton() }
        { content }
      </h3>
    )
  }

  renderCancelButton() {
    return <button className="btn btn-link btn-block text-muted font-size-sm" onClick={ this.cancel }>{ I18n.t('link.intro.cancel') }</button>;
  }

  renderForm() {
    switch(this.state.status) {
      case 'intro': {
        return (
          <div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                { this.renderHeader(I18n.t('link.aisp.title')) }
                <div className="mb-4">
                  { parse(I18n.t('link.aisp.title_description')) }
                </div>

                <button className="btn btn-block btn-primary font-weight-bold" onClick={ this.start } >{ I18n.t('link.aisp.intro.next') }</button>
                <p>
                  <small>
                    { parse(I18n.t('link.aisp.intro.consent_text', { name: this.props.customerName })) } <button className="btn btn-link text-left p-0" onClick={ this.policies }><small>{I18n.t('link.intro.terms_of_use_title')}</small></button>
                  </small>
                </p>
                { this.renderCancelButton() }
              </div>
            </div>

          </div>
        )
        break;
      }
      case 'aispAuth': {
        return (
          <React.Fragment>
            <TransactionAggregator { ...this.props} dataId={ this.state.dataId } callback={ this.aispCallback } />
            { this.renderCancelButton() }
          </React.Fragment>
        )
        break;
      }
      case 'policies': {
        return (
          <React.Fragment>
            { this.renderHeader(I18n.t('link.policies.title')) }
            <div className="row justify-content-center overflow-auto">
              <div className="col-md-12">
                <ul className="list-unstyled">
                  {
                    I18n.t('link.aisp.policies.points').map((pointText, idx) => {
                      return (
                        <li className="mb-4" key={ idx }>
                          <i className="fas fa-check-circle text-primary" /> <span className="lead">{ pointText.title }</span>
                          <div>{ pointText.description }</div>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="mb-4">
                  <button className="btn btn-primary text-left mr-4" onClick={ this.privacyPolicy }>{ I18n.t('link.policies.privacy_policy') }</button>
                  <button className="btn btn-primary text-left" onClick={ this.termsOfUse }>{ I18n.t('link.policies.terms_of_use') }</button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'termsOfUse': {
        return (
          <React.Fragment>
            <div className="row justify-content-center overflow-auto h-100">
              <div className="col-md-12" style={{ height: '75vh' }}>
                <iframe width="100%" height="100%" frameBorder="0" src="/terms"></iframe>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'privacyPolicy': {
        return (
          <React.Fragment>
            <div className="row justify-content-center overflow-auto h-100">
              <div className="col-md-12" style={{ height: '75vh' }}>
                <iframe width="100%" height="100%" frameBorder="0" src="/theia_privacy"></iframe>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'collect_data': {
        return (
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('link.collect_data.message') }</div>
            </div>
          </div>
        )
      }
      case 'redirect': {
        return (
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('link.redirect.message') }</div>
            </div>
          </div>
        )
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        { this.renderForm() }
      </React.Fragment>
    );
  }
}

TheiaCollector.propTypes = {
  dataSubmitUrl: PropTypes.string,
  callbackUrl: PropTypes.string,
  dataCollectionURL: PropTypes.string,
  authenticityToken: PropTypes.string,
  agreementsUrl: PropTypes.string,
  agreementBankData: PropTypes.array,
  clientId: PropTypes.string,
  scope: PropTypes.string,
  test: PropTypes.bool,
  customerName: PropTypes.string,
  customerConfig: PropTypes.object,
};
export default TheiaCollector
