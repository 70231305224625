import React from "react";
import ToastIcon from "./ToastIcon";

const Toast = ({ typeOfToast, children }) => {
  const separatorColor = (typeOfToast) => {
    switch (typeOfToast) {
      case "success":
        return "#00DB90";
      case "info":
        return "#2E6CC6";
      case "warning":
        return "#EE9500";
      case "danger":
        return "#EA4E2C";
      default:
        return "#00DB90";
    }
  };
  return (
    <div className={`toast toast-${typeOfToast}`} style={{ opacity: 1 }} id="toast-component">
      <div
        className="toast-separator"
        style={{ backgroundColor: separatorColor(typeOfToast) }}
      ></div>
      <div className="toast-content">
        <ToastIcon typeOfIcon={typeOfToast} />
        <div
          className="toast-message"
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </div>
    </div>
  );
};
export default Toast;
