(function ($) {
  "use strict"; // Start of use strict

  $(function () {
    const isExpanded = localStorage.getItem("sidebarexpanded");
    if (isExpanded == "true" || isExpanded == null) {
      localStorage.setItem("sidebarexpanded", "true");
      $(".sidebar").removeClass("toggled");
      $(".content-wrapper").removeClass("content-wrapper-lite");
    } else {
      localStorage.setItem("sidebarexpanded", "false");
      $(".sidebar").addClass("toggled");
      $(".content-wrapper").addClass("content-wrapper-lite");
    }
  });

  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
    $("body").toggleClass("sidebar-toggled");
    $(".content-wrapper").toggleClass("content-wrapper-lite");
    $(".sidebar").toggleClass("toggled");
    if ($(".content-wrapper").hasClass("content-wrapper-lite")) {
      $(".content-wrapper .content-wrapper-lite").collapse("hide");
    }
    if ($(".sidebar").hasClass("toggled")) {
      localStorage.setItem("sidebarexpanded", "false");
    } else {
      localStorage.setItem("sidebarexpanded", "true");
    }
  });

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function () {
    if ($(window).width() < 768) {
      $(".sidebar .collapse").collapse("hide");
    }
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $("body.fixed-nav .sidebar").on(
    "mousewheel DOMMouseScroll wheel",
    function (e) {
      if ($(window).width() > 768) {
        var e0 = e.originalEvent,
          delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      }
    }
  );

  // Scroll to top button appear
  $(document).on("scroll", function () {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $(".scroll-to-top").fadeIn();
    } else {
      $(".scroll-to-top").fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on("click", "a.scroll-to-top", function (e) {
    var $anchor = $(this);
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $($anchor.attr("href")).offset().top,
        },
        500,
        "swing"
      );
    e.preventDefault();
  });
})(jQuery); // End of use strict
