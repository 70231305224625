var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

class User {
  constructor (attrs) {
    this.name = attrs.loans.summary.name;
    this.nationalId = attrs.loans.summary.customerId;

    if (attrs.personal) {
      this.birthDate = new Date(attrs.personal.innlogget.fodselsdato);
      this.ssn = attrs.personal.innlogget.foedselsEllerDNummer;
      this.fullAddress = `${attrs.personal.innlogget.bostedsadresse1} ${attrs.personal.innlogget.bostedsadresse2} ${attrs.personal.innlogget.bostedsadresse3}`;
      this.citizenshipCode = Number(attrs.personal.innlogget.landDsfKode);
      this.married = attrs.personal.innlogget.erGift;
      this.cohabitant = attrs.personal.innlogget.harRegistrertPartner;
      this.divorsed = attrs.personal.innlogget.erSkilt;
      this.separated = attrs.personal.innlogget.erSeparert;
      this.hasKids = attrs.personal.innlogget.harBarn;
    }

    // this.phone_number = attrs.cars.mobil;
    // this.email = attrs.cars.epost;
    // this.cars_count = attrs.cars.cars_count;

    // TODO: refactor to model
    this.totalUnsecuredDebt = (attrs.loans.summary.totalInterestBearingBalanceCreditFacility + attrs.loans.summary.totalNonInterestBearingBalanceCreditFacility) / 100;
    this.loans =
      attrs.loans.list.filter((el) => el.type != 'gjenpartsbrev')
        .map((cf) => cf.loans.map(loan => {
            return {
              coApplicant: Number(loan.coBorrower),
              limit: Number(loan.creditLimit) / 100,
              nominalRate: Number(loan.nominalInterestRate) / 100,
              balance: (loan.balance ? Number(loan.balance) : (Number(loan.interestBearingBalance) + Number(loan.nonInterestBearingBalance))) / 100,
              bankName: loan.financialInstitutionName,
              bankOrgID: loan.financialInstitutionID,
            }
          }
        )).flat()

    // Income from skatteetaten
    const docs = attrs.income?.dokumenter;
    if (docs) {
      const parts = attrs.income.parter;
      this.incomePayments = docs.filter((doc) => doc.inntekt).map((doc) => {
        const incomeStatements = doc.inntekt.filter(income => income.loennsinntekt).map(income => {
            return {
              isCash: income.fordel == 'kontantytelse',
              amount: Number(income.beloep),
              type: income.loennsinntekt.beskrivelse,
              isPermanent: income.loennsinntekt.beskrivelse == 'fastloenn',
            }
          });
        const conditions = doc.arbeidsforhold.map(condition => {
            return {
              startDate: new Date(condition.startdato),
              endDate: condition.sluttdato && new Date(condition.sluttdato),
              weekHours: condition.antallTimerPerUkeSomEnFullStillingTilsvarer,
              positionAmount: Number(condition.stillingsprosent),
              laidOff: condition.permisjon,
            }
          });

        return {
          companyName: parts[doc.leveranseIdentifikator.norskIdentifikatorOppgaveGiver].visningsnavn,
          conditions: conditions,
          earliestStart: new Date(Math.min.apply(null, doc.arbeidsforhold.map(conditions => new Date(conditions.startdato)))),
          latestEnd: (conditions.map(condition => condition.endDate).filter(date => date).length > 0 || null) && new Date(Math.max.apply(null, conditions.map(condition => condition.endDate))),
          maxPositionAmount: Math.max(conditions.filter(condition => condition.positionAmount).map(condition => condition.positionAmount)),
          income: incomeStatements,
          permanentIncome: incomeStatements.filter(income => income.isPermanent).map(income => income.amount).reduce((a, b) => a + b, 0),
          totalIncome: incomeStatements.map(income => income.amount).reduce((a, b) => a + b, 0),
          period: new Date(doc.periode),
        }
      });

      this.incomePayments = Object.entries(groupBy(this.incomePayments, 'companyName')).map(values => {
        values = values[1];
        let maxVal = new Date(Math.max.apply(null, values.map(val => val.period)));

        return values.filter(val => val.period.getTime() === maxVal.getTime())[0];
      })
    }

    console.log(this);
  }

  civilStatus() {
    if (this.hasKids === undefined) { return };

    if (this.married) { return 'Married' }
    if (this.cohabitant) { return 'Have partner' }
    if (this.divorsed) { return 'Divorced' }
    if (this.separated) { return 'Separated' }

    return 'Single';
  }

  kids() {
    if (this.hasKids === undefined) { return };

    return this.hasKids ? 'Has kid(-s)' : 'No kids'
  }

  addAgreements(agreements) {
    this.loans = this.loans.map(loan => {
      const agreementInfo = agreements.filter(agreement => Number(agreement.corpId) === Number(loan.bankOrgID))[0]

      if (agreementInfo) {
        loan.accountNumber = agreementInfo.corpAccount;
        loan.kid = agreementInfo.kid;
      }

      return loan;
    });
  }

  addInvoices(invoices) {
    this.loans = this.loans.map(loan => {
      const invoiceInfo = invoices.filter(invoice => Number(invoice.org_number) === Number(loan.bankOrgID))[0]

      if (invoiceInfo) {
        loan.accountNumber = invoiceInfo.account;
        loan.kid = invoiceInfo.kid;
      }

      return loan;
    });
  }
}

export default User
