require("./server_rendering");

import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

import Clipboard from "clipboard/dist/clipboard.min.js";
import toastr from "toastr";

import { DataTable } from "simple-datatables";

function number_format(number, decimals, dec_point, thousands_sep) {
  // *     example: number_format(1234.56, 2, ',', ' ');
  // *     return: '1 234,56'
  number = (number + "").replace(",", "").replace(" ", "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

const reportCharts = document.querySelectorAll(".report-chart"),
  // reportColors = ['rgba(0, 129, 216, 0.5)', 'rgba(0, 148, 224, 0.5)', 'rgba(0, 165, 222, 0.5)', 'rgba(0, 181, 212, 0.5)', 'rgba(0, 195, 193, 0.5)', 'rgba(0, 208, 170, 0.5)', 'rgba(0, 219, 144, 0.5)'];
  reportColors = [
    "rgba(46, 108, 198, 0.2)",
    "rgba(0, 219, 144, 0.2)",
    "rgba(248, 209, 80, 0.2)",
    "rgba(238, 149, 0, 0.2)",
  ];
reportCharts.forEach((chart) => {
  const labels = chart.dataset.labels?.split(",");

  var reportChart = new Chart(chart, {
    type: "radar",
    data: {
      labels: [
        "Household & Services",
        "Other",
        "Food & Drinks",
        "Home impovements / repair",
        "Leisure",
        "Shopping",
        "Health & Beauty",
        "Transport",
      ],
      datasets: JSON.parse(chart.dataset.data).map((values, idx) => {
        return {
          data: values,
          fill: true,
          label: labels[idx],
          // backgroundColor: 'rgba(255, 99, 132, 0.2)',
          // borderColor: 'rgb(255, 99, 132)',
          // pointBackgroundColor: 'rgb(255, 99, 132)',
          // pointBorderColor: '#fff',
          // pointHoverBackgroundColor: '#fff',
          // pointHoverBorderColor: 'rgb(255, 99, 132)'
          backgroundColor: reportColors[idx],
          borderColor: "rgba(0, 165, 222, 0.5)",
          pointRadius: 3,
          gridLineColor: "#8096DE",
          // pointBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointBorderColor: "rgba(78, 115, 223, 1)",
          // pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointHoverBorderColor: "rgba(78, 115, 223, 1)",
        };
      }),
    },
    options: {
      elements: {
        line: {
          borderWidth: 0,
        },
        point: {
          hoverRadius: 6,
        },
      },
      scales: {
        r: {
          angleLines: {
            color: "#8096DE",
          },
          ticks: {
            color: "#B7BDD0",
            callback: function (value, index, values) {
              return `${number_format(value)} kr`;
            },
            font: {
              weight: 500,
              size: 12,
            },
          },
          grid: {
            color: "#8096DE",
            zeroLineColor: "rgb(234, 236, 244)",
            drawBorder: false,
            borderDash: [2],
            zeroLineBorderDash: [2],
          },
          pointLabels: {
            color: " #2E6CC6",
            font: {
              weight: 500,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          symbolWidth: 80,
          symbolHeight: 0,
          symbolRadius: 0,
          pointSyle: "line",
          labels: {
            color: "#C4C4C4",
            font: {
              weight: 500,
            },
          },
        },
        tooltip: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleColor: "#2E6CC6",
          bodyColor: "#102463",
          titleMarginBottom: 5,
          titleFontColor: "#6e707e",
          titleFontSize: 14,
          borderColor: "#dddfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
          callbacks: {
            label: (tooltipItem) => {
              console.log(tooltipItem);
              return `${number_format(tooltipItem.raw)} kr/${
                tooltipItem.dataset.label
              }`;
            },
          },
        },
      },
    },
  });
});

const progressCharts = document.querySelectorAll(".progress-chart"),
  progressChartsColor = (value) => {
    switch (true) {
      case value <= 25:
        return "EF253D";
        break;
      case value <= 50:
        return "FFCD2B";
        break;
      case value <= 75:
        return "2E6CC6";
        break;
      case value <= 100:
        return "00DB90";
        break;
    }
  };

progressCharts.forEach((chart) => {
  var progressChart = new Chart(chart, {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [chart.dataset.data, 100 - chart.dataset.data],
          // fill: true,
          // backgroundColor: 'rgba(255, 99, 132, 0.2)',
          // borderColor: 'rgb(255, 99, 132)',
          // pointBackgroundColor: 'rgb(255, 99, 132)',
          // pointBorderColor: '#fff',
          // pointHoverBackgroundColor: '#fff',
          // pointHoverBorderColor: 'rgb(255, 99, 132)'
          backgroundColor: [
            `#${progressChartsColor(chart.dataset.data)}`,
            "#F3F3F3",
          ],
          borderWidth: 0,
          // borderColor: "rgba(78, 115, 223, 1)",
          // pointBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointBorderColor: "rgba(78, 115, 223, 1)",
          // pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointHoverBorderColor: "rgba(78, 115, 223, 1)",
        },
      ],
    },
    options: {
      responsive: true,
      cutout: "50%",
      elements: {
        line: {
          borderWidth: 3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
  });
});

const spendingsDoughnutCharts = document.querySelectorAll(
  ".spendings-doughnut-chart"
);
const spendingsDoughnutChartsColor = [
  "#2C71C4",
  "#02DB90",
  "#EF263D",
  "#FBB601",
  "#263238",
  "#455A64",
  "#C4C4C4",
];
// const spendingsDoughnutChartsColor = ['#4EDA79', '#72D963', '#90D64C', '#ACD236', '#C6CD20', '#E0C608', '#F9BE00'];

spendingsDoughnutCharts.forEach((chart) => {
  const data = JSON.parse(chart.dataset.data);
  var spendingsDoughnutChart = new Chart(chart, {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: data.data,
          // fill: true,
          // backgroundColor: 'rgba(255, 99, 132, 0.2)',
          // borderColor: 'rgb(255, 99, 132)',
          // pointBackgroundColor: 'rgb(255, 99, 132)',
          // pointBorderColor: '#fff',
          // pointHoverBackgroundColor: '#fff',
          // pointHoverBorderColor: 'rgb(255, 99, 132)'
          backgroundColor: spendingsDoughnutChartsColor,
          borderWidth: 0,
          // borderColor: "rgba(78, 115, 223, 1)",
          // pointBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointBorderColor: "rgba(78, 115, 223, 1)",
          // pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointHoverBorderColor: "rgba(78, 115, 223, 1)",
        },
      ],
      labels: data.labels,
    },
    options: {
      responsive: true,
      cutout: "75%",
      elements: {
        line: {
          borderWidth: 3,
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "right",
        },
        tooltip: {
          enabled: true,
        },
      },
    },
  });
});

const speedCharts = document.querySelectorAll(".speed-chart"),
  speedChartsColor = (value) => {
    switch (true) {
      case value <= 0:
        return "EF253D";
        break;
      case value > 0:
        return "00DB90";
        break;
    }
  };
speedCharts.forEach((chart) => {
  const value = parseFloat(chart.dataset.data);

  var progressChart = new Chart(chart, {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [
            Math.min.apply(Math, [100 + value, 100]),
            Math.abs(value),
            Math.min.apply(Math, [100 - value, 100]),
          ],
          backgroundColor: [
            `#F3F3F3`,
            `#${speedChartsColor(value)}`,
            "#F3F3F3",
          ],
          hoverBackgroundColor: [
            `#F3F3F3`,
            `#${speedChartsColor(value)}`,
            "#F3F3F3",
          ],
          borderWidth: 0,
        },
      ],
    },
    options: {
      responsive: true,
      rotation: -90,
      circumference: 180,
      cutout: "75%",
      maintainAspectRatio: false,
      animation: false,
      animations: {
        colors: false,
      },
      elements: {
        line: {
          borderWidth: 3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
  });
});

const disposableIncomeChart = document.getElementById(
  "disposable_income_chart"
);
if (disposableIncomeChart) {
  const colors = ["#2e6cc6", "#008fdf", "#00adda", "#00c7bc", "#00db90"],
    values = JSON.parse(disposableIncomeChart.dataset.data);
  console.log(values);
  var expenditureChart = new Chart(disposableIncomeChart, {
    type: "bar",
    data: {
      datasets: [
        {
          lineTension: 0.3,
          backgroundColor: values.map((val) =>
            val.y >= 0 ? "#00DB90" : "#EF253D"
          ),
          // borderColor: "rgba(78, 115, 223, 1)",
          // pointRadius: 0,
          // pointBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointBorderColor: "rgba(78, 115, 223, 1)",
          // pointHoverRadius: 3,
          // pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          // pointHoverBorderColor: "rgba(78, 115, 223, 1)",
          // pointHitRadius: 10,
          // pointBorderWidth: 2,
          data: values,
        },
      ],
    },
    options: {
      responsive: true,
      // aspectRatio: 3,
      scales: {
        x: {
          time: {
            unit: "date",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            maxRotation: 90,
            color: "#A5A5A9",
          },
        },
        y: {
          ticks: {
            // maxTicksLimit: 5,
            padding: 10,
            color: "#A5A5A9",
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return `${number_format(value)} kr`;
            },
          },
          grid: {
            color: "rgb(234, 236, 244)",
            zeroLineColor: "rgb(234, 236, 244)",
            drawBorder: false,
            borderDash: [2],
            zeroLineBorderDash: [2],
          },
        },
        // y: {
        //   beginAtZero: true
        // }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleColor: "#2E6CC6",
          bodyColor: "#102463",
          titleMarginBottom: 10,
          titleFontColor: "#6e707e",
          titleFontSize: 14,
          borderColor: "#dddfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: "index",
          caretPadding: 10,
          callbacks: {
            label: (tooltipItem) => {
              // const chart = tooltipItem.chart;
              // var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
              // return datasetLabel + ': $' + number_format(tooltipItem.yLabel);
              // return `${tooltipItem.label} : ${number_format(tooltipItem.raw.y)} kr`;
              return `${number_format(tooltipItem.raw.y)} kr`;
            },
          },
        },
      },
    },
  });
}

const balanceCharts = document.querySelectorAll(".balance-chart");
if (balanceCharts) {
  // const colors2 = ['#4EDA79', '#72D963', '#90D64C', '#ACD236', '#C6CD20', '#E0C608', '#F9BE00'];
  const colors2 = [
    "#2C71C4",
    "#02DB90",
    "#EF263D",
    "#FBB601",
    "#263238",
    "#455A64",
    "#C4C4C4",
  ];

  balanceCharts.forEach((chart) => {
    new Chart(chart, {
      type: "line",
      parsing: false,
      data: {
        // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: JSON.parse(chart.dataset.data).map((data, idx) => {
          return {
            // label: '# of Votes',
            // label: "",
            // backgroundColor: '#F00',
            // borderColor: '#F00',
            label: data.title,
            lineTension: 0.3,
            backgroundColor: "#FFF",
            borderColor: colors2[idx % colors2.length],
            pointRadius: 0,
            // pointBackgroundColor: "rgba(78, 115, 223, 1)",
            // pointBorderColor: "rgba(78, 115, 223, 1)",
            // pointHoverRadius: 3,
            // pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
            // pointHoverBorderColor: "rgba(78, 115, 223, 1)",
            // pointHitRadius: 10,
            // pointBorderWidth: 2,
            data: data.values,
            // xAxisID: 'x',
            // yAxisID: 'y',
            // backgroundColor: [
            //     'rgba(255, 99, 132, 0.2)',
            //     'rgba(54, 162, 235, 0.2)',
            //     'rgba(255, 206, 86, 0.2)',
            //     'rgba(75, 192, 192, 0.2)',
            //     'rgba(153, 102, 255, 0.2)',
            //     'rgba(255, 159, 64, 0.2)'
            // ],
            // borderColor: [
            //     'rgba(255, 99, 132, 1)',
            //     'rgba(54, 162, 235, 1)',
            //     'rgba(255, 206, 86, 1)',
            //     'rgba(75, 192, 192, 1)',
            //     'rgba(153, 102, 255, 1)',
            //     'rgba(255, 159, 64, 1)'
            // ],
            // borderWidth: 1
          };
        }),
      },
      options: {
        responsive: true,
        // aspectRatio: 3,
        // parsing: false,
        scales: {
          x: {
            type: "time",
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 7,
            },
          },
          y: {
            // beginAtZero: true,
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                return `${number_format(value)} kr`;
              },
            },
            grid: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2],
            },
          },
          // y: {
          //   beginAtZero: true
          // }
        },
        plugins: {
          // legend: {
          //   display: false,
          // },
          tooltip: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#858796",
            titleColor: "#2E6CC6",
            bodyColor: "#102463",
            titleMarginBottom: 10,
            titleFontColor: "#6e707e",
            titleFontSize: 14,
            borderColor: "#dddfeb",
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            intersect: false,
            mode: "nearest",
            caretPadding: 10,
            callbacks: {
              // title: (tooltipItem) => console.log(tooltipItem),
              label: (tooltipItem) => {
                // const chart = tooltipItem.chart;
                // var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                // return datasetLabel + ': $' + number_format(tooltipItem.yLabel);
                // return `${tooltipItem.label} : ${number_format(tooltipItem.raw.y)} kr`;
                return `${tooltipItem.dataset.label}: ${number_format(
                  tooltipItem.raw.y
                )} kr`;
              },
            },
          },
        },
      },
    });
  });
}

const ctx = document.getElementById("incomeChart");
if (ctx) {
  var incomeChart = new Chart(ctx, {
    type: "line",
    data: {
      // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [
        {
          // label: '# of Votes',
          // label: "",
          // backgroundColor: '#F00',
          // borderColor: '#F00',
          label: "Income",
          lineTension: 0.3,
          backgroundColor: "rgba(78, 115, 223, 0.05)",
          borderColor: "rgba(78, 115, 223, 1)",
          pointRadius: 0,
          pointBackgroundColor: "rgba(78, 115, 223, 1)",
          pointBorderColor: "rgba(78, 115, 223, 1)",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          pointHoverBorderColor: "rgba(78, 115, 223, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: JSON.parse(ctx.dataset.data).income,
          // xAxisID: 'x',
          // yAxisID: 'y',
          // backgroundColor: [
          //     'rgba(255, 99, 132, 0.2)',
          //     'rgba(54, 162, 235, 0.2)',
          //     'rgba(255, 206, 86, 0.2)',
          //     'rgba(75, 192, 192, 0.2)',
          //     'rgba(153, 102, 255, 0.2)',
          //     'rgba(255, 159, 64, 0.2)'
          // ],
          // borderColor: [
          //     'rgba(255, 99, 132, 1)',
          //     'rgba(54, 162, 235, 1)',
          //     'rgba(255, 206, 86, 1)',
          //     'rgba(75, 192, 192, 1)',
          //     'rgba(153, 102, 255, 1)',
          //     'rgba(255, 159, 64, 1)'
          // ],
          // borderWidth: 1
        },
        {
          fill: {
            target: "origin",
            below: "rgba(78, 115, 223, 0.01)",
          },
          label: "Reference budget",
          lineTension: 0.3,
          // backgroundColor: "rgba(78, 115, 223, 0.05)",
          borderColor: "#EF253D",
          borderDash: [15, 15],
          pointRadius: 0,
          pointBackgroundColor: "#EF253D",
          pointBorderColor: "#EF253D",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "#EF253D",
          pointHoverBorderColor: "#EF253D",
          pointHitRadius: 10,
          pointBorderWidth: 0,
          data: JSON.parse(ctx.dataset.data).budget,
        },
      ],
    },
    options: {
      responsive: true,
      aspectRatio: 3,
      scales: {
        x: {
          time: {
            unit: "date",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            maxTicksLimit: 7,
          },
        },
        y: {
          // beginAtZero: true,
          ticks: {
            maxTicksLimit: 5,
            padding: 10,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return `${number_format(value)} kr`;
            },
          },
          grid: {
            color: "rgb(234, 236, 244)",
            zeroLineColor: "rgb(234, 236, 244)",
            drawBorder: false,
            borderDash: [2],
            zeroLineBorderDash: [2],
          },
        },
        // y: {
        //   beginAtZero: true
        // }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleColor: "#2E6CC6",
          bodyColor: "#102463",
          titleMarginBottom: 10,
          titleFontColor: "#6e707e",
          titleFontSize: 14,
          borderColor: "#dddfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: "index",
          caretPadding: 10,
          callbacks: {
            label: (tooltipItem) => {
              // const chart = tooltipItem.chart;
              // var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
              // return datasetLabel + ': $' + number_format(tooltipItem.yLabel);
              // return `${tooltipItem.label} : ${number_format(tooltipItem.raw.y)} kr`;
              return `${tooltipItem.dataset.label}: ${number_format(
                tooltipItem.raw.y
              )} kr`;
            },
          },
        },
      },
    },
  });
}

const expenditureCtx = document.getElementById("expenditureChart");
if (expenditureCtx) {
  const colors = ["#2e6cc6", "#008fdf", "#00adda", "#00c7bc", "#00db90"];

  var expenditureChart = new Chart(expenditureCtx, {
    type: "bar",
    data: {
      datasets: Object.entries(JSON.parse(expenditureCtx.dataset.data)).map(
        ([key, values], idx) => {
          return {
            label: key,
            lineTension: 0.3,
            backgroundColor: colors[idx],
            // borderColor: "rgba(78, 115, 223, 1)",
            // pointRadius: 0,
            // pointBackgroundColor: "rgba(78, 115, 223, 1)",
            // pointBorderColor: "rgba(78, 115, 223, 1)",
            // pointHoverRadius: 3,
            // pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
            // pointHoverBorderColor: "rgba(78, 115, 223, 1)",
            // pointHitRadius: 10,
            // pointBorderWidth: 2,
            data: values,
          };
        }
      ),
    },
    options: {
      responsive: true,
      aspectRatio: 3,
      scales: {
        x: {
          time: {
            unit: "date",
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            maxTicksLimit: 7,
          },
        },
        y: {
          // beginAtZero: true,
          ticks: {
            maxTicksLimit: 5,
            padding: 10,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return `${number_format(value)} kr`;
            },
          },
          grid: {
            color: "rgb(234, 236, 244)",
            zeroLineColor: "rgb(234, 236, 244)",
            drawBorder: false,
            borderDash: [2],
            zeroLineBorderDash: [2],
          },
        },
        // y: {
        //   beginAtZero: true
        // }
      },
      plugins: {
        // legend: {
        //   display: false,
        // },
        tooltip: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleColor: "#2E6CC6",
          bodyColor: "#102463",
          titleMarginBottom: 10,
          titleFontColor: "#6e707e",
          titleFontSize: 14,
          borderColor: "#dddfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: "index",
          caretPadding: 10,
          callbacks: {
            label: (tooltipItem) => {
              // const chart = tooltipItem.chart;
              // var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
              // return datasetLabel + ': $' + number_format(tooltipItem.yLabel);
              // return `${tooltipItem.label} : ${number_format(tooltipItem.raw.y)} kr`;
              return `${tooltipItem.dataset.label}: ${number_format(
                tooltipItem.raw.y
              )} kr`;
            },
          },
        },
      },
    },
  });
}

let secretKey = document.querySelector("#secret_key");
if (secretKey) {
  $(secretKey).modal("show");
}

const statCharts = document.querySelectorAll(".stats");
if (statCharts) {
  statCharts.forEach((chart) => {
    const data = JSON.parse(chart.dataset.data);

    new Chart(chart, {
      type: "line",
      data: {
        datasets: [
          {
            label: data.this.label,
            borderColor: "rgba(78, 115, 223, 1)",
            pointRadius: 0,
            pointBackgroundColor: "rgba(78, 115, 223, 1)",
            pointBorderColor: "rgba(78, 115, 223, 1)",
            pointHoverRadius: 3,
            pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
            pointHoverBorderColor: "rgba(78, 115, 223, 1)",
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: data.this.values,
          },
          {
            label: data.last.label,
            borderColor: "rgba(0, 143, 223, 0.25)",
            // borderDash: [15, 15],
            pointRadius: 0,
            pointBackgroundColor: "rgba(0, 143, 223, 0.5)",
            pointBorderColor: "rgba(0, 143, 223, 0.5)",
            pointHoverRadius: 3,
            pointHoverBackgroundColor: "rgba(0, 143, 223, 0.5)",
            pointHoverBorderColor: "rgba(0, 143, 223, 0.5)",
            pointHitRadius: 10,
            pointBorderWidth: 0,
            data: data.last.values,
          },
        ],
      },
      options: {
        responsive: true,
        aspectRatio: 3,
        scales: {
          x: {
            time: {
              unit: "date",
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 7,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
              callback: function (value, index, values) {
                return number_format(value);
              },
            },
            grid: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#858796",
            titleColor: "#2E6CC6",
            bodyColor: "#102463",
            titleMarginBottom: 10,
            titleFontColor: "#6e707e",
            titleFontSize: 14,
            borderColor: "#dddfeb",
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            intersect: false,
            mode: "index",
            caretPadding: 10,
            callbacks: {
              label: (tooltipItem) =>
                `${tooltipItem.raw.rd}: ${number_format(tooltipItem.raw.y)}`,
            },
          },
        },
      },
    });
  });
}

// Copy link to clipboard
var linkCopy = new Clipboard(".copy-btn");
toastr.options = {
  preventDuplicates: true,
  positionClass: "toast-bottom-left",
  timeOut: 2000,
};
linkCopy
  .on("success", (event) => {
    var msg =
      $(event.trigger).data("success") ||
      "You link is copied and ready to be shared.";
    toastr["success"](msg);
  })
  .on("error", (_) => {
    var msg =
      $(event.trigger).data("error") ||
      "You link wasn't copied. Please, select it manually to share.";
    toastr["error"](msg);
  });

// TODO: add React or AJAX form submitting
// let userDatumInviteForm = document.querySelector('#user_datum_invite_form');
// if (userDatumInviteForm) {
//   userDatumInviteForm.addEventListener('submit', event => {
//     event.preventDefault();
//   })
// }

document.querySelectorAll(".simple-datatabled").forEach(
  (table) =>
    new DataTable(table, {
      perPage: 5,
      sortable:
        table.dataset.sortable != undefined
          ? table.dataset.sortable == "true"
          : true,
    })
);
// const dataTable = new DataTable("table.table", { perPage: 5 });

$(() => {
  $(".select2").select2();

  var initTooltip = (el) => {
    var skin = el.data("skin") ? "tooltip-" + el.data("skin") : "";
    var width = el.data("width") == "auto" ? "tooltop-auto-width" : "";
    var triggerValue = el.data("trigger") ? el.data("trigger") : "hover";
    var placement = el.data("placement") ? el.data("placement") : "top";

    el.tooltip({
      trigger: triggerValue,
      template:
        '<div class="tooltip ' +
        skin +
        " " +
        width +
        '" role="tooltip">\
          <div class="arrow"></div>\
          <div class="tooltip-inner"></div>\
      </div>',
    });
    el[0].dataset.tooptipInitialized = true;
  };

  var initTooltips = () => {
    // init bootstrap tooltips
    $('[data-toggle="kt-tooltip"]').each(function () {
      initTooltip($(this));
    });

    // $('body').on('mouseover', '[data-toggle="kt-tooltip"]:not([data-tooptip-initialized])', function() {
    //   initTooltip($(this));
    //   $(this).tooltip('show');
    // });
  };

  initTooltips();
});
