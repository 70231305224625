import React from "react";
import PropTypes from "prop-types";
import { WaveTopBottomLoading } from "react-loadingg";
import axios from "axios";
var parse = require("html-react-parser");

import TinkLink from "./TinkLink";

import I18n from "i18n/translations";

class TransactionAggregator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      loading: true,
      dataId: props.dataId,
      provider: this.props.provider,
      status:
        (props.financialInstitutions?.length > 0 && "overview") ||
        "add_account", // other statuses: 'overview'

      bankCredentials: [],
      // Format example: {id: "f58e31ebaf625c15a9601aa4deac83d0", name: "Demo Open Banking Redirect", logo_url: "https://cdn.tink.se/provider-images/placeholder.png"}
      financialInstitutions: props.financialInstitutions || [],
    };
  }

  componentDidMount() {
    this.init();
  }

  setErrorMessage = (error) => {
    this.setState({ errorMessage: error.toString(), loading: false });
  };

  init = () => {
    axios
      .post(
        this.props.aispInitUrl,
        {
          client_id: this.props.clientId,
          data_id: this.state.dataId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.status == "error") {
          throw Error(data.message);
        }

        if (data.status === "ok") {
          this.setState({
            loading: false,
            dataId: data.data_id,
            provider: data.provider,
          });
        } else {
          // TODO: add smth?
        }
      })
      .catch((error) => this.setErrorMessage(error));
  };

  reinit = () => {
    this.setState({ provider: null }, this.init);
  };

  bankCallback = (data) => {
    if (data.error) {
      // data scheme: { error: true, status: data.error.status, message: data.error.message }
      this.setErrorMessage(data.message);
      this.reinit();
    } else {
      this.setState({
        loading: true,
        bankCredentials: [...this.state.bankCredentials, data],
      });

      axios
        .post(
          this.props.aispCallbackUrl,
          {
            client_id: this.props.clientId,
            data_id: this.state.dataId,
            additional_data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == "error") {
            throw Error(data.message);
          }

          if (data.status === "ok") {
            this.reinit();
            this.setState({
              financialInstitutions: data.financial_institutions,
              status: "overview",
            });
            // this.props.callback(this.state.dataId);
          } else {
            // setTimeout(() => {
            //   this.bankCallback(data);
            // }, 3000)
          }
        })
        .catch((error) => this.setErrorMessage(error));
    }
  };

  restartSession = () => {
    this.setState({ loading: true, errorMessage: null });
    this.init();
  };

  callback = (error = undefined) => {
    this.props.callback(this.state.dataId, error);
  };

  renderLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div style={{ width: 420 }}>
          <div
            className="row align-items-center d-flex"
            style={{ minHeight: 350 }}
          >
            <div className="col-2">
              <div className="loading-squared">
                <WaveTopBottomLoading color="#4579aa" />
              </div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{I18n.t("bankid.loading")}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAddAccount = () => {
    if (this.state.status == "add_account" && !this.state.loading) {
      if (this.state.provider == "tink") {
        return (
          <React.Fragment>
            <TinkLink
              {...this.props}
              dataId={this.state.dataId}
              callback={this.bankCallback}
            />
            <button
              className="btn btn-link text-muted font-size-sm"
              onClick={this.reinit}
            >
              {I18n.t("aisp.restart")}
            </button>
          </React.Fragment>
        );
      }
    }
  };

  renderBankList = () => {
    if (this.state.status == "overview" && !this.state.loading) {
      return (
        <React.Fragment>
          {this.state.financialInstitutions.length > 0 && (
            <div>
              <p className="bank_list_description">
                {I18n.t("link.aisp.overview.bank_list_description")}
              </p>
              <p className="connected_bank_number">
                {I18n.t("aisp.bank_list", {
                  count: this.state.financialInstitutions.length,
                })}
              </p>
              <div id="financial_institutions">
                {this.state.financialInstitutions.map(
                  (financialInstitution, idx) => {
                    return (
                      <div
                        id="financial_institutions_item"
                        className="row align-items-center d-flex mx-0 mb-2"
                        key={idx}
                      >
                        <div className="col-1 px-0">
                          <img
                            src={financialInstitution.logo_url}
                            className="img-fluid bank-icon"
                          ></img>
                        </div>
                        <div className="col align-items-center d-flex">
                          <div>{financialInstitution.name}</div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <a
                className="link-cursor-pointer connect-bank-btn"
                onClick={() => {
                  this.setState({ status: "add_account" });
                }}
              >
                <div className="row align-items-center d-flex button-item">
                  <div className="col-1 px-0">
                    <i className="fas fa-plus-circle bank-icon"></i>
                  </div>
                  <div className="col align-items-center d-flex">
                    <div>
                      {I18n.t(
                        "link.aisp.transaction_aggregator.overview.add_account"
                      )}
                    </div>
                  </div>
                </div>
              </a>

              <button
                className="btn btn-block btn-primary font-weight-bold"
                onClick={this.callback}
              >
                {I18n.t("aisp.finished")}
              </button>
            </div>
          )}
        </React.Fragment>
      );
    }
  };
  // <a className="link-cursor-pointer" onClick={ this.callback }>
  //   <div className="row align-items-center d-flex mb-2">
  //     <div className="col-2">
  //     </div>
  //     <div className="col align-items-center d-flex">
  //       <div>{ I18n.t('aisp.finished') }</div>
  //     </div>
  //   </div>
  // </a>

  // <button className="btn btn-block btn-primary" onClick={ this.restartSession }>{ I18n.t('bankid.error.retry') }</button>
  render() {
    return (
      <React.Fragment>
        {this.state.errorMessage && (
          <div className="text-center">
            <p>{I18n.t("bankid.error.title")}</p>
            <p>{parse(this.state.errorMessage)}</p>
          </div>
        )}

        {this.state.loading && this.renderLoading()}
        {!this.state.loading && this.renderAddAccount()}
        {!this.state.loading && this.renderBankList()}
      </React.Fragment>
    );
  }
}

TransactionAggregator.propTypes = {
  authenticityToken: PropTypes.string,

  getAispUrl: PropTypes.string,
  aispCallbackUrl: PropTypes.string,
  aispInitUrl: PropTypes.string,

  clientId: PropTypes.string,
  dataId: PropTypes.string,

  // scope: PropTypes.string,
  test: PropTypes.bool,
  callback: PropTypes.func,
};
export default TransactionAggregator;
