import consumer from "./consumer"
import sha256 from 'crypto-js/sha256';

const hexHash = (msg) => sha256(msg);

// consumer.subscriptions.create("BankIdChannel", {
//   connected() {
//     // Called when the subscription is ready for use on the server
//   },

//   disconnected() {
//     // Called when the subscription has been terminated by the server
//   },

//   received(data) {
//     // Called when there's incoming data on the websocket for this channel
//   }
// });

export const subscribeToBankIDMobile = (phoneNumber, birthDate, callback) => {
  let subscription =
    consumer.subscriptions.create({ channel: "BankIdChannel", id: String(hexHash(`${birthDate}_${phoneNumber}`)) }, {
      received(data) {
        callback(data);
      }
    });

  return subscription;
}
