import React from "react"
import PropTypes from "prop-types"
import { WaveTopBottomLoading } from 'react-loadingg';
import axios from 'axios';
var parse = require('html-react-parser');

import I18n from "i18n/translations";

class TinkLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      loading: true,
      // loadingState: 'start',
      // dataId: props.dataId,
    };
  }

  componentDidMount() {
    this.getLink();
    window.addEventListener("message", this.messageCallback, false);
    // this.initTinkCallback();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.messageCallback);
  }

  messageCallback = (event) => {
    if(event.origin == 'https://link.tink.com') {
      const data = JSON.parse(event.data);

      console.log(data);

      // {"type":"status","data":{"loading":true}}
      if (data.data?.loading !== undefined) { this.setState({ loading: data.data.loading }) };

      // {"type":"none","additionalInformation":{"credentialsId":"342bf51bf10e4498a4b3458407b3f4bc"}}
      if (data.additionalInformation && data.type === 'none') { this.props.callback(data.additionalInformation) };

      if (data.type == 'error') { this.props.callback({ error: true, status: data.error.status, message: data.error.message }); }

      if (data.data?.element == 'BACK_BUTTON' && data.data?.screen == 'SUBMIT_CREDENTIALS_SCREEN') { this.props.callback({ reinit: true }); }
    }
  }

  setErrorMessage = (error) => {
    this.setState({ errorMessage: error.toString(), loading: false });
  }

  getLink = () => {
    this.setState({ loading: true });

    let url = new URL(this.props.getAispUrl, window.location.origin);

    axios.post(
      url,
      {
        client_id: this.props.clientId,
        data_id: this.props.dataId,
        test: this.props.test,
        financial_institution: this.props.financial_institution,
        market: this.props.market,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
    })
    .then(({data}) => {
      if (data.error_message) {
        throw Error(data.message);
      }

      this.setState({ aispUrl: data.auth_link });
    })
    .catch(error => this.setErrorMessage(error));
  }

  renderLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div style={{ width: 420 }}>
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('bankid.loading') }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFrame = () => {
    if (this.state.aispUrl) {
      return (
        <div className={ `justify-content-center overflow-hidden ${ this.state.loading && 'd-none' || 'd-flex' }` }>
          <iframe width="420" height="600" frameBorder="0" src={ this.state.aispUrl } />
        </div>
      )
    }
  }

  render () {
    return (
      <React.Fragment>
        {
          this.state.errorMessage &&
            <div className="text-center">
              <p>{ I18n.t('bankid.error.title') }</p>
              <p>{ parse(this.state.errorMessage) }</p>
              <button className="btn btn-block btn-primary" onClick={ this.restartSession }>{ I18n.t('bankid.error.retry') }</button>
            </div>
        }

        { this.state.loading && this.renderLoading() }
        { this.renderFrame() }
      </React.Fragment>
    );
  }
}

TinkLink.propTypes = {
  authenticityToken: PropTypes.string,
  getAispUrl: PropTypes.string,
  aispCallbackUrl: PropTypes.string,
  clientId: PropTypes.string,
  dataId: PropTypes.string,
  scope: PropTypes.string,
  test: PropTypes.bool,
  callback: PropTypes.func,
  market: PropTypes.string,
};
export default TinkLink
