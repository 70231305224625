import React from "react"
import PropTypes from "prop-types"
import User from 'models/User'
import { WaveTopBottomLoading } from 'react-loadingg';

import BankID from './BankID'
import TransactionAggregator from './TransactionAggregator'
import { subscribeToBankIDMobile } from '../channels/bank_id_channel'
var parse = require('html-react-parser');

import I18n from "i18n/translations";

const errorMessages = {
  BAD_REQUEST: 'Bad request or wrong request parameters. Please, check the link',
  USER_CANCELLED: 'User canselled interactions',
  INTERNAL_ERROR: 'Something went wrong. Service is currently unavailable. Please, try again later.',
  TEMPORARY_ERROR: 'Service is currently partially unavailable due to a provider issue. Please, try again in a minute.',
}

class Easybank extends React.Component {
  constructor(props) {
    super(props);
    // Set locale
    I18n.locale = props.locale;
    // console.log(I18n.t('errors.bad_request'), I18n.locale);

    this.navigationStack = [];

    this.config = {
      ...{
          introDescription: I18n.t('link.intro.description'),
          showPolicyPrompt: true,
        },
      ...(props.customerConfig || {})[I18n.locale]
    };

    let status = props.scope == ['insights'] ? 'aispAuth' : 'intro';
    if (props.consentGiven) { status = 'bankID' }

    this.state = {
      status: status,
      consentGiven: props.consentGiven || false,
      taxOrganisationsOfInterest: props.taxOrganisationsOfInterest || '',
    };
  }

  withInsights = () => this.props.scope.includes('insights')

  pushCurrentStatus = () => {
    this.navigationStack.push(this.state.status);
  }

  start = (event) => {
    this.pushCurrentStatus();
    if (this.props.scope.split(',').some(item => ["vehicles", "unsecured_loans", "info", "income", "student_loan", "e_invoices", "tax_data_pdf"].includes(item))) {
      this.setState({ status: 'bankID' });
    } else {
      this.setState({ status: 'aispAuth' });
    }
  }

  cancel = () => {
    this.callback(null, 'error', 'USER_CANCELLED');
  }

  policies = () => {
    this.pushCurrentStatus()
    this.setState({ status: 'policies' });
  }

  termsOfUse = () => {
    this.pushCurrentStatus();
    this.setState({ status: 'termsOfUse' });
  }

  privacyPolicy = () => {
    this.pushCurrentStatus();
    this.setState({ status: 'privacyPolicy' });
  }

  goBack = () => {
    this.setState({ status: this.navigationStack.pop() });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    let name = target.name;

    if (target.name.match(/phone/)) {
      name = 'phoneNumber';
    } else if (target.name.match(/birth/)) {
      name = 'birthDate';
    }

    this.setState({
      [name]: value
    });
  }

  changeConsent = (event) => {
    this.setState({ consentGiven: event.target.checked });
  }

  redirectBack = (code = null, errorType = null) => {
    let params = {};
    const errorMessage = errorMessages[errorType],
          url = new URL(this.props.callbackUrl, document.baseURI);

    if (errorType) {
      params.type = 'error';

      if (this.props.iframe) {
        params.error = {
          status: errorType,
          message: errorMessage,
        };
      } else {
        params.error = errorType;
        params.message = errorMessage;
      }
    } else {
      params.type = 'code';
      params.code = code;
    }

    if (this.props.iframe) {
      window.parent.postMessage(params, '*');
    } else {
      Object.entries(params).forEach(([key, val]) => url.searchParams.append(key, val));

      window.location = `${url}`;
    }
  }

  callback = (code, status, errorType = null, test_status) => {
    let errorMessage = errorMessages[errorType];
    const data = { code: code, status: status, error: errorType, authenticity_token: this.props.authenticityToken,
      client_id: this.props.clientId, scope: this.props.scope, test: this.props.test, test_status: test_status,
      tax_entities: this.state.taxOrganisationsOfInterest };
    const url = new URL(this.props.callbackUrl, document.baseURI);
    // console.log(this.state, this);

    if (errorType) {
      this.setState({ status: 'redirect' });

      this.redirectBack(null, errorType);

      // delete data.code;
      // if (this.props.iframe) {
      //   window.parent.postMessage({ status: data.status, error: { status: data.error, message: errorMessage } }, window.parent.location.origin);
      // } else {
      //   // TODO: Render error message
      //   // this.setState({ status: 'error', errorMessage: data.error });
      //   url.searchParams.append('status', data.status);
      //   url.searchParams.append('error', data.error);
      //   url.searchParams.append('message', errorMessage);
      //   window.location = `${url}`;

      //   // window.location = `${url}?status=${ data.status }&error=${ data.error }&message=${errorMessage}`;

      //   // alert(data.error);
      // }
    } else {
      this.setState({ status: 'collect_data' });

      fetch(this.props.dataCollectionURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(resp => resp.json())
      .then(jsonResp => {
        const dataId = jsonResp.data_id;

        if (this.withInsights()) {
          this.setState({ status: 'aispAuth', dataId: dataId });
        } else {
          this.setState({ status: 'redirect' });

          this.redirectBack(dataId);
        }


        // if (this.props.iframe) {
        //   window.parent.postMessage({ code: jsonResp.data_id }, window.parent.location.origin);
        // } else {
        //   url.searchParams.append('code', jsonResp.data_id);
        //   window.location = `${url}`;
        // }
      })
      .catch(error => alert('Smth went wrong'));
    }

    // if (this.props.iframe) {
    //   window.parent.postMessage(data, window.parent.location.origin);
    // } else {
    //   // Plan A - POST request
    //   // var form = document.createElement('form');

    //   // document.body.appendChild(form);
    //   // form.method = 'post';
    //   // form.action = url;
    //   // for (var name in data) {
    //   //   var input = document.createElement('input');
    //   //   input.type = 'hidden';
    //   //   input.name = name;
    //   //   input.value = data[name];
    //   //   form.appendChild(input);
    //   // }
    //   // form.submit();

    //   // Plan B - sync request
    //   // Collect the data according to the scope
    //   fetch(this.props.dataCollectionURL, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data),
    //   })
    //   .then(resp => resp.json())
    //   .then(jsonResp => {
    //     this.setState({ status: 'redirect' });
    //     window.location = `${url}?code=${jsonResp.dataId}`;
    //   })
    //   .catch(error => alert('Smth went wrong'));
    // }
  }

  // Error format: { status: 'USER_CANCELLED', message: '' }
  aispCallback = (dataId, error = undefined) => {
    this.setState({ status: 'redirect' });
    const url = this.props.callbackUrl,
          message = { code: dataId, error: error?.message };

    if (this.props.iframe) {
      window.parent.postMessage(message, '*');
    } else {
      const params = new URLSearchParams(Object.fromEntries(Object.entries(message).filter(([key, val]) => val))).toString();
      window.location = `${url}?${params}`;
    }
  }

  renderBackButton() {
    if (this.state.prevStatus) {
      return (
        <button className="btn btn-link float-left" onClick={ this.goBack }>
          <i className="fas fa-angle-left" />
        </button>
      )
    }
  }

  renderHeader(content) {
    return (
      <h3 className="text-center">
        { this.renderBackButton() }
        { content }
      </h3>
    )
  }

  renderCancelButton() {
    return <button className="btn btn-link btn-block text-muted font-size-sm" onClick={ this.cancel }>{ I18n.t('link.intro.cancel') }</button>;
  }

  renderForm() {
    switch(this.state.status) {
      case 'intro': {
        return (
          <div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                { this.renderHeader(I18n.t('link.intro.title')) }
                {
                  this.config.consentScreenHTML &&
                    parse(this.config.consentScreenHTML) ||
                    <React.Fragment>
                      <p>{ this.config.introDescription }</p>
                      <ul className="list-unstyled mb-4 pl-4">
                        <li className="mb-4">
                          <i className="fas fa-check-circle text-primary" /> <span className="lead">{ I18n.t('link.intro.steps.auth') }</span>
                        </li>
                        <li className="mb-4">
                          <i className="fas fa-check-circle text-primary" /> <span className="lead">{ I18n.t('link.intro.steps.collect') }</span>
                        </li>
                        {
                          this.props.scope.includes('e_invoices') &&
                            <li className="mb-4">
                              <i className="fas fa-check-circle text-primary" /> <span className="lead">{ I18n.t('link.intro.steps.e_invoices') }</span>
                            </li>
                        }
                      </ul>
                      {
                        this.config.showPolicyPrompt &&
                          <button className="btn btn-link text-left" onClick={ this.policies }>{ I18n.t('link.intro.consent') }</button>
                      }
                    </React.Fragment>
                }
                {
                  this.props.scope.includes('tax_data_pdf') && (!this.props.taxOrganisationsOfInterest || this.props.taxOrganisationsOfInterest.length == 0) &&
                    <div className="form-group required tax_entities">
                      <label className="form-control-label required" htmlFor="taxOrganisationsOfInterest">{ I18n.t('bankid.inputs.tax_entities') } <abbr title="required">*</abbr></label>
                      <input className="form-control string required" autoComplete="off" autoCorrect="off" spellCheck="off" name="taxOrganisationsOfInterest" id="tax_organisations_of_interest" autoFocus onChange={ this.handleInputChange } value={ this.state.taxOrganisationsOfInterest } />
                    </div>
                }
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck" onChange={ this.changeConsent } value={ this.state.consentGiven }/>
                  <label className="custom-control-label" htmlFor="customCheck">{ parse(I18n.t('link.intro.consent_checkbox_label', { name: this.props.customerName })) } <button className="btn btn-link text-left p-0" onClick={ this.policies }>{I18n.t('link.intro.terms_of_use_title')}</button></label>
                </div>
                <button className="btn btn-block btn-primary font-weight-bold" onClick={ this.start } disabled={ !this.state.consentGiven }>{ I18n.t('link.intro.next') }</button>
                { this.renderCancelButton() }
              </div>
            </div>

          </div>
        )
        break;
      }
      case 'bankID': {
        return (
          <React.Fragment>
            { this.renderHeader(I18n.t('link.bankid.title')) }
            <p className="text-center">{ I18n.t('link.bankid.title_description') }</p>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <ul className="list-unstyled mb-4">
                  <li className="mb-4">
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">{ I18n.t('link.bankid.data_sources.general').title }</span>
                    <div>{ this.props.scope.split(',').map(item => I18n.t('link.bankid.data_sources.general.description')[item]).filter(item => item).join(', ') }.</div>
                  </li>
                  {
                    this.props.scope.includes('e_invoices') &&
                      <li className="mb-4">
                        <i className="fas fa-check-circle text-primary" /> <span className="lead">{ I18n.t('link.bankid.data_sources.e_invoices').title }</span>
                        <div>{ I18n.t('link.bankid.data_sources.e_invoices').description }</div>
                      </li>
                  }
                </ul>
                <BankID
                  bankdIDUrl={ this.props.bankdIDUrl }
                  bankdIDMobileUrl={ this.props.bankdIDMobileUrl }
                  bankdIDTestUrl={ this.props.bankdIDTestUrl }

                  authenticityToken={ this.props.authenticityToken }
                  bankidSessionUrl={ this.props.bankidSessionUrl }
                  clientId={ this.props.clientId }
                  test={ this.props.test }
                  callback={ this.callback }
                />
              </div>
              { this.renderCancelButton() }
            </div>
          </React.Fragment>
        );

        break;
      }
      case 'aispAuth': {
        return (
          <React.Fragment>
            { this.renderHeader(I18n.t('link.aisp.title')) }
            <div>{ parse(I18n.t('link.aisp.title_description')) }</div>
            <TransactionAggregator { ...this.props} dataId={ this.state.dataId } callback={ this.aispCallback } />
            { this.renderCancelButton() }
          </React.Fragment>
        )
        break;
      }
      case 'policies': {
        return (
          <React.Fragment>
            { this.renderHeader(I18n.t('link.policies.title')) }
            <div className="row justify-content-center overflow-auto">
              <div className="col-md-12">
                <ul className="list-unstyled">
                  {
                    I18n.t('link.policies.points').map((pointText, idx) => {
                      return (
                        <li className="mt-4" key={ idx }>
                          <i className="fas fa-check-circle text-primary" /> <span className="lead">{ pointText.title }</span>
                          <div>{ pointText.description }</div>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="mb-4">
                  <button className="btn btn-primary text-left mr-4" onClick={ this.privacyPolicy }>{ I18n.t('link.policies.privacy_policy') }</button>
                  <button className="btn btn-primary text-left" onClick={ this.termsOfUse }>{ I18n.t('link.policies.terms_of_use') }</button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'termsOfUse': {
        return (
          <React.Fragment>
            <div className="row justify-content-center overflow-auto h-100">
              <div className="col-md-12" style={{ height: '75vh' }}>
                <iframe width="100%" height="100%" frameBorder="0" src="/terms_kb"></iframe>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'privacyPolicy': {
        return (
          <React.Fragment>
            <div className="row justify-content-center overflow-auto h-100">
              <div className="col-md-12" style={{ height: '75vh' }}>
                <iframe width="100%" height="100%" frameBorder="0" src="/privacy_kb"></iframe>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'collect_data': {
        return (
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('link.collect_data.message') }</div>
            </div>
          </div>
        )
      }
      case 'redirect': {
        return (
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('link.redirect.message') }</div>
            </div>
          </div>
        )
      }
    }
  }

      // <div className="container">
  render () {
    return (
      <React.Fragment>
        { this.renderForm() }
      </React.Fragment>
    );
  }
}

Easybank.propTypes = {
  dataSubmitUrl: PropTypes.string,
  callbackUrl: PropTypes.string,
  dataCollectionURL: PropTypes.string,
  authenticityToken: PropTypes.string,
  agreementsUrl: PropTypes.string,
  agreementBankData: PropTypes.array,
  clientId: PropTypes.string,
  scope: PropTypes.string,
  test: PropTypes.bool,
  customerName: PropTypes.string,
  customerConfig: PropTypes.object,
  taxOrganisationsOfInterest: PropTypes.string,
};
export default Easybank
